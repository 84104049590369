import InterventionNotificationValidationModel     from 'Models/intervention/InterventionNotificationValidationModel';
import Alert                                       from 'antd/lib/alert';
import Button                                      from 'antd/lib/button';
import Card                                        from 'antd/lib/card';
import Col                                         from 'antd/lib/col';
import Row                                         from 'antd/lib/row';
import Space                                       from 'antd/lib/space';
import { Buffer }                                  from 'buffer';
import LogoComponent                               from 'components/LogoComponent';
import { Page }                                    from 'components/Page';
import { getInterventionStatusLabelByReference }   from 'constants/InterventionStatuses';
import { observer }                                from 'mobx-react';
import moment                                      from 'moment';
import Proto                                       from 'proto/proto';
import { useParams }                               from 'react-router-dom';
import React                                       from 'react';
import notificationApiError                        from 'tools/notificationApiError';
import { notificationSuccess }                     from 'tools/notification';
import './InterventionValidationPage.scss';

const { InterventionNotificationValidationData } = Proto.Service.Intervention;

const InterventionValidationPage = () => {
	const { token } = useParams() as { token: string; };

	const interventionNotificationValidation = React.useMemo(() => new InterventionNotificationValidationModel(), []);

	const postInterventionNotificationValidation = async (validate: boolean) => {
		const interventionNotificationValidation = new InterventionNotificationValidationModel({
			token,
			validated: validate,
		});

		interventionNotificationValidation
			.save()
			.then(() => {
				notificationSuccess({ message: 'Décision enregistrée !' });
				setValidated(validate);
			})
			.catch(notificationApiError);
	};

	const [validated, setValidated] = React.useState<boolean|undefined>(undefined);

	React.useEffect(() => {
		interventionNotificationValidation.setId(token);

		interventionNotificationValidation
			.fetch()
			.catch(() => {});
	}, []);

	const _renderCardContent = React.useCallback(() => {
		const data = InterventionNotificationValidationData.decode(
			Buffer.from(interventionNotificationValidation.data, 'base64'),
		);
		const interventionProto = data.intervention;
		const interventionStatusRef = interventionProto?.interventionStatus?.reference;
		const interventionStatusLabel = interventionStatusRef
			? getInterventionStatusLabelByReference(interventionStatusRef)
			: null;
		const validationDisabled = !!interventionStatusRef && interventionStatusRef !== 'waiting_pre_validation';

		const scheduleStartDate = data.intervention?.scheduleStartDate?.seconds
			? moment.unix(data.intervention?.scheduleStartDate?.seconds as number)
			: null;
		const scheduleEndDate = data.intervention?.scheduleEndDate?.seconds
			? moment.unix(data.intervention?.scheduleEndDate?.seconds as number)
			: null;
		const isGroup = scheduleStartDate && scheduleEndDate
			? scheduleEndDate?.diff(scheduleStartDate, 'hours') > 14
			: false;

		return (
			<Space
				direction="vertical"
				size={24}
				style={{ width: '50%' }}
			>
				{validationDisabled && (
					<Alert
						description={
							`La confirmation ou le refus sont impossibles pour ce${isGroup ? 's' : ''} rendez-vous, `
							+ `${isGroup ? `les interventions` : `l'intervention`} étant au statut "${interventionStatusLabel}".`
						}
						message="Attention"
						showIcon
						type="error"
					/>
				)}
				<div className="InterventionValidationPage__title">
					<h3 style={{ color: 'var(--primary-color)', marginBottom: 0, paddingBottom: 0 }}>
						Confirmation du planning d&apos;intervention
					</h3>
					{!!scheduleStartDate && !!scheduleEndDate && isGroup && (
						<>
							Du {scheduleStartDate.format('DD/MM/YYYY')} à {scheduleStartDate.format('HH[h]mm')}
							<br />Au {scheduleEndDate.format('DD/MM/YYYY')} à {scheduleEndDate.format('HH[h]mm')}
						</>
					)}
					{!!scheduleStartDate && !isGroup && (
						<>
							Le {scheduleStartDate.format('DD/MM/YYYY')} à {scheduleStartDate.format('HH[h]mm')}
						</>
					)}
				</div>
				<div>
					<div className="label">
						Prestations
					</div>
					<div>
						{data.operations.map(operation => operation.label).join(', ')}
					</div>
				</div>
				<div>
					<div className="label">
						{`Lieu d'intervention`}
					</div>
					<div>
						{data.taskZone?.name}<br />
						{data.taskZone?.address}<br />
						{data.taskZone?.zipCode} {data.taskZone?.city}
					</div>
				</div>
				<div>
					<div className="label">
						Intervenants
					</div>
					<div>
						{data.technicians.map(technician => (
							<div key={technician.lastName}>
								{technician.lastName?.toUpperCase()} {technician.firstName}
							</div>
						))}
					</div>
				</div>
				<div>
					<div className="label">
						Véhicules
					</div>
					<div>
						{data.vehicles.map(vehicle => (
							<div key={vehicle.name}>
								{vehicle.licensePlate}
							</div>
						))}
					</div>
				</div>
				<div>
					Nous vous remercions de nous répondre sous 48h afin de faciliter la
					planification de votre intervention.
				</div>
				<div>
					{validated !== undefined ? (
						<Alert
							message={`Intervention ${validated ? 'confirmée' : 'refusée'}.`}
							showIcon
							type={validated ? 'success' : 'error'}
						/>
					) : (
						<Space direction="vertical" size="small" style={{ width: '100%' }}>
							<Button
								block
								disabled={validationDisabled}
								onClick={() => postInterventionNotificationValidation(true)}
								size="large"
								style={{ fontSize: 'large' }}
								type="primary"
							>
								Valider le calendrier d&apos;intervention
							</Button>
							<Button
								block
								danger
								disabled={validationDisabled}
								onClick={() => postInterventionNotificationValidation(false)}
								size="large"
								style={{ height: 0, padding: 0 }}
								type="link"
							>
								Demander une modification
							</Button>
						</Space>
					)}
				</div>

			</Space>
		);
	}, [validated]);

	return (
		<Page
			title="Confirmation du rendez-vous"
		>
			<Row
				className="InterventionValidationPage"
				gutter={20}
			>
				<Col
					span={24}
					style={{ textAlign: 'center' }}
				>
					<LogoComponent />
				</Col>

				<Col
					md={{
						offset: 4,
						span: 16,
					}}
					xs={{
						offset: 2,
						span: 20,
					}}
				>
					<Card
						loading={interventionNotificationValidation.isLoading}
					>
						{!interventionNotificationValidation.isLoaded ? (
							<div>
								{`Le rendez-vous n'existe pas ou a déjà été validé.`}
							</div>
						) : _renderCardContent()}
					</Card>
				</Col>
			</Row>
		</Page>
	);
};

export default observer(InterventionValidationPage);
