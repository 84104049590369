import resolvable          from 'decorators/resolvable';
import moment              from 'moment';
import { downloadAsync }   from 'tools/downloadFile';
import FilePrivateApiModel from '../../abstracts/FilePrivateApiModel';
import FileTypeSourceModel from './FileTypeSourceModel';

export type FileFileStatusReference = 'available' | 'to_delete';

export type FileTypeReference =
	'contact_export_csv'
	| 'order_form_pdf'
	| 'pricing_conditions_pdf'
	| 'additional_documents'
	| 'maintenance_document'
	| 'insurance_document'
	| 'vehicle_picture'
	| 'vehicle_document'
	| 'vehicle_other'
	| 'damage_document'
	| 'violation_ticket_document'
	| 'dressing_picture'
	| 'amenity_picture'
	| 'supplier_enterprise_purchase_condition_levy_mandate'
	| 'contract_iteration_document'
	| 'contract_iteration_quotation_description'
	| 'contract_iteration_document_technician'
	| 'contract_iteration_dcfour'
	| 'contract_iteration_contract'
	| 'quotation_dcfour'
	| 'quotation_document'
	| 'quotation_document_technician'
	| 'quotation_quotation_description'
	| 'quotation_quotation'
	| 'purchase_order'
	| 'framework_agreement_iteration_document_pdf'
	| 'framework_agreement_iteration_signed_document'
	| 'framework_agreement_iteration_other'
	| 'invoice_pdf'
	| 'report_report'
	| 'report_certificate'
	| 'report_sheet'
	| 'contract_iteration_document_for_client'
	| 'quotation_document_for_client'
	| 'intervention_client_attachment'
;

export default class FileModel extends FilePrivateApiModel {
	public filters: ModelFiltersExtended<{
		'fileStatus': id;
		'fileStatus.reference': FileFileStatusReference;
		'typeSource': id;
		'typeSource.source': id;
		'typeSource.source.entityUrn': string;
		'typeSource.type': id;
		'typeSource.type.reference': FileTypeReference;
		'typeSource.type.service': id;
		'typeSource.type.service.serviceUrn': string;
	}> = {};

	static get path(): string {
		return '/stonecode_file_files/{?id}';
	}
	
	@resolvable(FileTypeSourceModel, { attributeName: 'typeSource' })
	declare typeSource: FileTypeSourceModel;

	public get date(): Moment {
		return moment(this.get('date'));
	}

	public get file(): string {
		return this.get('file');
	}

	public get name(): string {
		return this.get('name');
	}

	public get size(): number {
		return this.get('size');
	}

	public get fileStatusId() {
		return this.get('fileStatus.id');
	}

	public get mimeTypeId() {
		return this.get('mimeType.id');
	}

	public get typeSourceId() {
		return this.get('typeSource.id');
	}

	public async download(name?: string) {
		await downloadAsync(this.downloadPath, name || this.name);
	}

	public get downloadPath() {
		return (this.isLoaded && !!this.id) ? this.get('@url') : '';
	}
}
